import { ConsentPolicy } from '@wix/cookie-consent-policy-client'
import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { WixCodeApiFactoryArgs, ConsentPolicyChangedHandler } from '@wix/thunderbolt-symbols'
import { MemberDetails, memberDetailsFromDTO, SiteMembersSiteConfig } from 'feature-site-members'
import type {
	SiteMembersWixCodeSdkHandlers,
	SiteMembersWixCodeSdkWixCodeApi,
	LegacySiteMembersWixCodeSdkWixCodeApi,
	LoginHandler,
	RegistrationOptions,
	LegacyRegistrationResult,
	RegistrationResult,
} from '../types'
import { UserErrors, REGISTRATION_RESULT_STATUS_DISPLAY } from '../types'
import { getWithCaptchaChallengeHandler } from '../utils'
import { namespace, memberNamespace } from '../symbols'
import { User } from '../user/user'
import { apis, formatPlatformizedHttpError, handleErrors, serializeMemberRoles } from '../user/utils'
import { validateEmailUserParams } from './validations'

export async function SiteMembersSdkFactory({
	featureConfig,
	handlers,
	platformEnvData,
	platformUtils,
}: WixCodeApiFactoryArgs<SiteMembersSiteConfig, never, SiteMembersWixCodeSdkHandlers>): Promise<{
	[memberNamespace]?: SiteMembersWixCodeSdkWixCodeApi
	[namespace]: LegacySiteMembersWixCodeSdkWixCodeApi
}> {
	const { smToken, smcollectionId, isPreviewMode } = featureConfig
	const isUserLoggedIn = !!smToken
	const {
		login,
		applySessionToken,
		promptForgotPassword,
		promptLogin,
		register,
		registerToUserLogin,
		registerToMemberLogout,
		logout,
		getMemberDetails,
		handleOauthToken,
		sendSetPasswordEmail,
	}: SiteMembersWixCodeSdkHandlers = handlers
	const {
		locationManager,
		sessionService,
		biUtils,
		essentials,
		consentPolicyManager,
		wixCodeNamespacesRegistry,
	} = platformUtils
	const shouldExposeWixMembersApi = platformEnvData.site.experiments['specs.thunderbolt.veloWixMembers']
	const shouldExposeSendSetPasswordApi =
		platformEnvData.site.experiments['specs.thunderbolt.exposeSendSetPasswordInVelo']
	const {
		window: { isSSR },
		bi: biData,
		location: { externalBaseUrl: baseUrl },
	} = platformEnvData
	const { svSession, msId } = biData
	const getMembersApi = async () => {
		const membersNgApi = await import('@wix/ambassador-members-ng-api/http')
		return membersNgApi.MembersNgApi('/_api/members').Members()({
			'x-wix-client-artifact-id': 'thunderbolt',
			authorization: sessionService.getWixCodeInstance(),
		})
	}

	const getMemberDetailsUrl = new URL(
		`/_api/wix-sm-webapp/member/${smToken}?collectionId=${smcollectionId}&metaSiteId=${msId}`,
		locationManager.getLocation().href
	).href

	const getMemberDetailsForSSR: () => Promise<MemberDetails | null> = () =>
		isUserLoggedIn
			? self
					.fetch(getMemberDetailsUrl, {
						headers: {
							'x-wix-client-artifact-id': 'thunderbolt',
						},
					})
					.then((r) => r.json())
					.then((r) => (r.errorCode ? null : memberDetailsFromDTO(r.payload)))
			: Promise.resolve(null)

	const memberDetails = isSSR ? await getMemberDetailsForSSR() : await getMemberDetails().catch(() => null)
	const currentUser = new User(
		{ ...memberDetails, uid: memberDetails?.id, svSession },
		memberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[memberDetails.status] : undefined,
		baseUrl,
		sessionService.getWixCodeInstance()
	)
	let onLogin: Array<LoginHandler> = []
	let onLogout: Array<() => void> = []

	const sendUserEmailApi = apis.sendUserEmailApi(baseUrl)
	const fedopsLogger = createFedopsLogger({
		appName: 'site-members-wix-code-sdk',
		biLoggerFactory: biUtils.createBiLoggerFactoryForFedops(biData),
		phasesConfig: 'SEND_START_AND_FINISH',
		customParams: {
			viewerName: 'thunderbolt',
		},
		factory: essentials.createFedopsLogger,
	})
	const _login: LegacySiteMembersWixCodeSdkWixCodeApi['login'] = async (email, password, options) => {
		const withCaptchaChallengeHandler = getWithCaptchaChallengeHandler(wixCodeNamespacesRegistry)
		if (options?.recaptchaToken) {
			await login(email, password, options)
		} else {
			await withCaptchaChallengeHandler((recaptchaToken) =>
				login(email, password, { ...(options || {}), recaptchaToken })
			)
		}
	}
	const _emailUser: LegacySiteMembersWixCodeSdkWixCodeApi['emailUser'] = async (emailId, toUser, options) => {
		fedopsLogger.interactionStarted('email-user')
		let processedOptions
		try {
			processedOptions = validateEmailUserParams(emailId, toUser, options).processedOptions
		} catch (err) {
			fedopsLogger.interactionEnded('email-user')
			throw err
		}
		const params = { emailId, memberId: toUser, options: processedOptions }
		const response = await fetch(sendUserEmailApi, {
			method: 'POST',
			headers: { authorization: sessionService.getWixCodeInstance() || '' },
			body: JSON.stringify(params),
		})
		if (!response.ok) {
			throw new Error(await response.text())
		}
		fedopsLogger.interactionEnded('email-user')
	}
	type IPromptLogin = (
		isMembersApi: boolean
	) =>
		| LegacySiteMembersWixCodeSdkWixCodeApi['promptLogin']
		| SiteMembersWixCodeSdkWixCodeApi['authentication']['promptLogin']
	const _promptLogin: IPromptLogin = (isMembersApi: boolean) => async (options) => {
		await promptLogin(options)
		// We can count on currentUser being updated because login is guaranteed not to
		// resolve before all onLogin callbacks (including the one that updates currentMember)
		// have resolved.
		if (!isMembersApi) {
			return legacyApi.currentUser
		}
		return getMember()
	}
	type IRegister = (
		isMembersApi: boolean
	) =>
		| LegacySiteMembersWixCodeSdkWixCodeApi['register']
		| SiteMembersWixCodeSdkWixCodeApi['authentication']['register']
	const _register: IRegister = (isMembersApi: boolean) => async (
		email: string,
		password: string,
		options: RegistrationOptions = {}
	) => {
		const withCaptchaChallengeHandler = getWithCaptchaChallengeHandler(wixCodeNamespacesRegistry)
		try {
			const data = options?.recaptchaToken
				? await register(email, password, options)
				: await withCaptchaChallengeHandler((recaptchaToken) =>
						register(email, password, { ...(options || {}), recaptchaToken })
				  )
			const response = {
				status: data.status,
				...(data.approvalToken ? { approvalToken: data.approvalToken } : {}),
			}
			if (isMembersApi) {
				return {
					...response,
					member: await getMember(),
				} as RegistrationResult
			}
			const user = new User(
				{
					uid: data.user?.id,
					svSession,
					...data.user,
				},
				REGISTRATION_RESULT_STATUS_DISPLAY[data.status],
				baseUrl,
				sessionService.getWixCodeInstance()
			)
			return {
				...response,
				user,
			} as LegacyRegistrationResult
		} catch (error) {
			if (error.message) {
				console.error(error.message)
				return Promise.reject(error.message)
			}
			// TODO: Shouldn't we handle a case where there is no error message?
		}
	}

	const getMember = async () => {
		// TODO: rename to member
		if (!isUserLoggedIn) {
			return undefined
		}
		const membersApi = await getMembersApi()
		const { member } = await membersApi.getMyMember({ fieldSet: 'FULL' })

		return member
	}

	const makeProfilePublic = async () => {
		// TODO: rename to member
		if (!isUserLoggedIn) {
			return undefined
		}
		const membersApi = await getMembersApi()
		const { member } = await membersApi.joinCommunity({})

		return member
	}

	const makeProfilePrivate = async () => {
		// TODO: rename to member
		if (!isUserLoggedIn) {
			return undefined
		}
		const membersApi = await getMembersApi()
		const { member } = await membersApi.leaveCommunity({})

		return member
	}

	const getRoles = () => {
		if (!isUserLoggedIn) {
			return Promise.reject(UserErrors.NO_LOGGED_IN)
		}
		return fetch(apis.currentUserRolesUrl(baseUrl), {
			headers: { authorization: sessionService.getWixCodeInstance() || '' },
		})
			.then(handleErrors)
			.then(serializeMemberRoles)
			.catch((error: any) => Promise.reject(formatPlatformizedHttpError(error)))
	}

	const legacyApi: LegacySiteMembersWixCodeSdkWixCodeApi = {
		currentUser,
		login: _login,
		applySessionToken,
		emailUser: _emailUser,
		promptForgotPassword,
		promptLogin: _promptLogin(false),
		register: _register(false),
		onLogin(handler: LoginHandler) {
			onLogin = [...onLogin, handler]
		},
		logout,
		async handleOauthToken(token: string, provider: string, mode: string, joinCommunityStatus: string) {
			await handleOauthToken(token, provider, mode, joinCommunityStatus)
		},
		getCurrentConsentPolicy: () => {
			return consentPolicyManager.getDetails()
		},
		_getConsentPolicyHeader: () => {
			return consentPolicyManager.getHeader()
		},
		setConsentPolicy: (policy: ConsentPolicy) => {
			return consentPolicyManager.setPolicy(policy)
		},
		resetConsentPolicy: () => {
			return consentPolicyManager.resetPolicy()
		},
		onConsentPolicyChanged: (handler: ConsentPolicyChangedHandler) => {
			return consentPolicyManager.onChanged(handler)
		},
		supportsPopupAutoClose: true,
	}

	const api: SiteMembersWixCodeSdkWixCodeApi = {
		currentMember: {
			getMember,
			makeProfilePublic,
			makeProfilePrivate,
			getRoles,
		},
		authentication: {
			login: _login,
			applySessionToken,
			promptForgotPassword,
			promptLogin: _promptLogin(true),
			register: _register(true),
			onLogin(handler: LoginHandler) {
				onLogin = [...onLogin, handler]
			},
			onLogout(handler: () => void) {
				onLogout = [...onLogout, handler]
			},
			logout,
			...(shouldExposeSendSetPasswordApi ? { sendSetPasswordEmail } : {}),
		},
		async handleOauthToken(token: string, provider: string, mode: string, joinCommunityStatus: string) {
			await handleOauthToken(token, provider, mode, joinCommunityStatus)
		},
		supportsPopupAutoClose: true,
	}

	if (process.env.browser && !isPreviewMode) {
		registerToUserLogin(async () => {
			const newMemberDetails = await getMemberDetails()
			legacyApi.currentUser = new User(
				{ ...newMemberDetails, uid: newMemberDetails?.id, svSession },
				newMemberDetails ? REGISTRATION_RESULT_STATUS_DISPLAY[newMemberDetails.status] : undefined,
				baseUrl,
				sessionService.getWixCodeInstance()
			)
			onLogin.forEach((handler) => {
				try {
					handler(legacyApi.currentUser)
				} catch (e) {
					console.error(e)
				}
			})
		})

		registerToMemberLogout(async () => {
			onLogout.forEach((handler) => {
				try {
					handler()
				} catch (e) {
					console.error(e)
				}
			})
		})
	}

	return {
		[namespace]: legacyApi,
		...(shouldExposeWixMembersApi ? { [memberNamespace]: api } : {}),
	}
}
